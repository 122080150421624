/* eslint-disable max-classes-per-file */
import * as Sentry from '@sentry/browser';

// Utilities
import { featureFlagEnabled } from 'common/utils/helpers';

// log errors
export const logError = (error, context = {}) => {
  Object.entries(context).forEach((entries) => {
    const [key, value] = entries;
    Sentry.setContext(key, value);
  });
  Sentry.captureException(error);
  if (featureFlagEnabled('sentry-feedback-modal')) {
    Sentry.showReportDialog();
  }
};
